<template>
  <div>
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <i class="fas fa-user"></i><span class="ml-3">User</span>
      </h3>

      <span class="float-right green-btn">
        <a class="btn btn-success mr-3" @click="create">
          <i class="material-icons">add</i> <span>User</span></a
        >
      </span>
    </div>
    <div></div>
    <div class="container mt-10" style="background-color: #ffffff">
      <b-table
        class="table table-bordered"
        show-empty
        :items="GetUsers"
        :fields="fields"
        :current-page="currentPage"
        :per-page="0"
        striped
        hover
        select-mode="single"
        selectable
        sort-by="userName"
      >
        <template #cell(actions)="row">
          <a @click="Edit(row.item)" class="mr-1">
            <i class="far fa-eye edit_btn"></i>
          </a>
          <button
            v-b-modal.confirmModal
            @click="sendInfo(row.item.id)"
            class="mr-1"
          >
            <i class="fas fa-trash-alt del_btn"></i>
          </button>
        </template>
        <template #cell(ResendCredentials)="row">
          <v-btn
            class="ml-3"
            @click="ResendCredentials(row.item)"
            v-if="!row.item.isVerified"
            small
            >Resend credentials</v-btn
          >
        </template>
      </b-table>
      <div class="row">
        <div class="col-6">
          <b-pagination
            size="md"
            v-if="perPage != '-1'"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
          ></b-pagination>
        </div>
        <div class="col-6 text-right">
          <span style="margin-right: 10px"><label for="">Show : </label></span>
          <select
            v-model="perPage"
            class="form-select"
            style="border: groove; width: 6%"
            aria-label="Default select example"
            @change="Search()"
          >
            <option selected>50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="-1">All</option>
          </select>

          <span style="margin-left: 10px; margin-right: 20px">
            <label for=""> Entries </label></span
          ><span>TOTAL RECORDS: {{ TotalUsers }}</span>
        </div>
      </div>
    </div>
    <!-- <div
      v-else
      class="container mt-10"
      style="background-color: #ffffff; text-align: center"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div> -->

    <b-modal size="xl" id="user" centered no-close-on-backdrop>
      <template slot="modal-title">Users</template>
      <v-container data-app fluid>
        <v-row align="center">
          <div
            class="form-group col-md-12 mb-form"
            :class="{ invalid: $v.form.userName.$error }"
          >
            <v-text-field
              v-model.trim="$v.form.userName.$model"
              maxlength="50"
              :disabled="isEditAble"
              label=" User Name"
            >
              <template #label>
                Full Name
                <span class="requiredfield"><strong> * </strong></span>
              </template>
            </v-text-field>
            <div class="invalid-feedback" v-if="$v.form.userName.$error">
              <span v-if="$v.form.userName.$error">Full Name is required</span>
            </div>
          </div>
          <div
            class="form-group col-md-12 mb-form"
            :class="{ invalid: $v.form.userEmail.$error }"
          >
            <v-text-field
              v-model.trim="$v.form.userEmail.$model"
              maxlength="50"
              :disabled="isEditAble"
              label="User Email"
            >
              <template #label>
                User Email
                <span class="requiredfield"><strong> * </strong></span>
              </template>
            </v-text-field>
            <div class="invalid-feedback" v-if="$v.form.userEmail.$error">
              <span v-if="$v.form.userEmail.$error"
                >User Email is required</span
              >
            </div>
          </div>
          <!-- <div
            class="form-group col-md-12 mb-form"
            :class="{ invalid: $v.userPassword.$error }"
          >
            <v-text-field 
              v-model.trim="$v.userPassword.$model"
              maxlength="50"
            >
              <template #label>
                user Password
                <span class="requiredfield"><strong> * </strong></span>
              </template>
            </v-text-field>
            <div class="invalid-feedback" v-if="$v.userPassword.$error">
              <span v-if="$v.userPassword.$error">user Password is required</span>
            </div>
          </div> -->
          <div
            class="form-group col-md-12 mb-form"
            :class="{ invalid: $v.form.userRole.$error }"
          >
            <v-select
              v-model.trim="$v.form.userRole.$model"
              :items="items"
              label="Select Role"
            ></v-select>
            <div class="invalid-feedback" v-if="$v.form.userRole.$error">
              <span v-if="$v.form.userRole.$error">Please Select a Role</span>
            </div>
          </div>
        </v-row>
      </v-container>
      <template #modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button v-if="form.id" size="lg" variant="success" @click="ok()">
          Update
          <span v-if="isSending">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </span>
        </b-button>
        <b-button v-else size="lg" variant="success" @click="ok()">
          Create
          <span v-if="isSending">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </span>
        </b-button>
        <b-button size="lg" variant="danger" @click="cancel()">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
      </template>
    </b-modal>
    <b-modal
      centered
      text
      id="confirmModal"
      title="Are you sure you want to Delete?"
      hide-footer
    >
      <div class="text-center">
        <p>Are you sure you want to delete?</p>
        <span class="green-btn">
          <a class="btn btn-success mr-3" @click="remove(form.id)"
            ><span>YES I'M SURE</span>
            <span v-if="isRemoving">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular> </span
          ></a>
        </span>
        <span class="green-btn">
          <a class="btn btn-danger mr-3" @click="$bvModal.hide('confirmModal')"
            ><span>Cancel</span></a
          >
        </span>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  validations: {
    form: {
      userName: {
        required,
        minLength: minLength(1),
      },
      userEmail: {
        required,
        email,
        minLength: minLength(1),
      },
      userRole: {
        required,
        minLength: minLength(1),
      },
    },
  },
  data() {
    return {
      isEditAble: true,
      isfetching: false,
      isRemoving: false,
      isSending: false,
      userid: "",
      items: [
        "Super Admin",
        "Admin Level 2",
        "JFS",
        "Home Health",
        "Admin Level 1",
      ],
      fields: [
        {
          key: "userName",
          label: "User Name",
        },
        {
          key: "email",
          label: "  Email",
        },
        {
          key: "userType",
          label: "  Role",
        },
        {
          key: "actions",
          label: "Action",
        },
        {
          key: "ResendCredentials",
          label: "ResendCredentials",
        },
      ],
      currentPage: 1,
      perPage: 50,
      totalItems: 10,
      search: "",
      form: {
        id: "",
        userName: "",
        userEmail: "",
        userPassword: "",
        userRole: "",
      },

      phoneNumber: "",
      location: "",
      id: "",
      type: "",
      caseworkers: [],
      rules: {
        required: (value) => !!value || "User Name is Required.",
        counter: (value) => value.length <= 50 || "Max 50 characters",
      },
    };
  },
  updated() {
    this.users;
  },
  watch: {
    currentPage: {
      handler: function () {
        this.fetchData().catch((error) => {
          console.error(error);
        });
      },
    },
    totalRow: {
      handler: function () {},
    },
  },
  mounted() {
    this.fetchData().catch((error) => {
      console.error(error);
    });
    this.totalItems = this.GetUsers.length;
  },
  computed: {
    ...mapGetters(["GetUsers", "totalRow"]),
    TotalUsers() {
      return this.GetUsers.length;
    },
  },
  methods: {
    ResendCredentials(item) {
      this.$store
        .dispatch("resendCredentials", item)
        .then((response) => {
          this.isSending = false;
          this.fetchData();
          if (response.message == "Success") {
            Swal.fire({
              title: "",
              text: "Email successfully sent",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          } else {
            Swal.fire({
              title: "",
              text: response.message,
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
            });
          }
          this.form.id = "";
          this.form.userName = "";
          this.form.userEmail = "";
          this.form.userRole = "";
          this.$v.$reset();
          this.$bvModal.hide("user");
        })
        .catch((ex) => {
          this.isSending = false;
          Swal.fire({
            title: "",
            text: "Error!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        });
    },
    sendInfo(item) {
      this.form.id = item;
    },
    create() {
      this.isEditAble = false;
      this.form.id = "";
      this.$bvModal.show("user");
    },
    Search() {
      this.fetchData();
    },
    async fetchData() {
      this.isfetching = true;
      this.search = this.search == null ? "" : this.search;
      await this.$store
        .dispatch("getUsers", {
          pageNo: this.currentPage,
          pageSize: this.perPage,
          filter: this.search,
        })
        .then((response) => {
          if (response.message == "Success") {
          }
          this.userName = "";
          this.userEmail = "";
          this.userRole = "";
          this.$v.$reset();
          this.isfetching = false;
        })
        .catch((ex) => {
          this.isfetching = false;
        });
    },
    onRowSelected(items) {
      this.Edit(items[0]);
    },
    Edit(obj) {
      if (obj != undefined) {
        this.form.id = obj.id;
        this.form.userName = obj.userName;
        this.form.userEmail = obj.email;
        this.form.userRole = obj.userType;
        this.isEditAble = true;
        this.$bvModal.show("user");
      }
    },
    async remove(obj) {
      this.isRemoving = true;
      this.$store
        .dispatch("removeuser", obj)
        .then((response) => {
          this.isRemoving = false;
          this.fetchData();
          if (response.status) {
            Swal.fire({
              title: "",
              text: "The user has been Deleted Successfully!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          }
        })
        .catch((ex) => {
          this.isRemoving = false;
          Swal.fire({
            title: "",
            text: "Error!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        });
      this.$bvModal.hide("confirmModal");
    },
    ok() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isSending = true;
        if (this.form.id === "") {
          this.$store
            .dispatch("saveusers", {
              UserName: this.form.userName,
              Email: this.form.userEmail,
              UserType: this.form.userRole,
            })
            .then((response) => {
              this.isSending = false;
              this.fetchData();
              if (response.message == "Success") {
                Swal.fire({
                  title: "",
                  text: "This user has been created successfully!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
              } else {
                Swal.fire({
                  title: "",
                  text: response.message,
                  icon: "error",
                  confirmButtonClass: "btn btn-secondary",
                });
              }
              this.form.id = "";
              this.form.userName = "";
              this.form.userEmail = "";
              this.form.userRole = "";
              this.$v.$reset();
              this.$bvModal.hide("user");
            })
            .catch((ex) => {
              this.isSending = false;
              Swal.fire({
                title: "",
                text: "Error!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
              });
            });
        } else {
          this.$store
            .dispatch("saveusers", {
              id: this.form.id,
              UserName: this.form.userName,
              Email: this.form.userEmail,
              UserType: this.form.userRole,
            })
            .then((response) => {
              this.isSending = false;
              this.fetchData();
              if (response.message == "Success") {
                Swal.fire({
                  title: "",
                  text: "This user has been updated successfully!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
              } else {
                Swal.fire({
                  title: "",
                  text: response.message,
                  icon: "error",
                  confirmButtonClass: "btn btn-secondary",
                });
              }
              this.form.id = "";
              this.form.userName = "";
              this.form.userEmail = "";
              this.isEditAble = false;
              this.form.userRole = "";
              this.$v.$reset();
              this.$bvModal.hide("user");
            })
            .catch((ex) => {
              this.isSending = false;
              Swal.fire({
                title: "",
                text: "Error!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
              });
            });
        }
      }
    },
    editClientinfo() {
      this.isEditAble = false;
    },

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
  },
};
</script>
<style scoped>
.error--text {
  color: rgba(252, 9, 9, 0.7) !important;
}
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px !important;
}
.table {
  box-shadow: 0 0 5px #ccc;
}
.row {
  margin: 0px !important;
}
.requiredfield {
  color: rgba(252, 9, 9, 0.7);
}
.form-group.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}

.edit_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.edit_btn:hover {
  color: #fb8c00 !important;
}
.del_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.del_btn:hover {
  color: #bf360c !important;
}
</style>
